// import "@bam-spark/nav-toggle";
require('waypoints/lib/noframework.waypoints.js');


let toggle = document.querySelector('.js-nav-toggle');

toggle.addEventListener('click', function () {
  // let scrollPosition = window.scrollY;

  if (toggle.classList.contains('is-active')) {
    // Close Toggle
    toggle.classList.remove('is-active');
    document.body.classList.remove("menu-open");
    this.setAttribute('aria-expanded', 'false');

    // let top = document.body.style.top;
    // document.body.style.position = '';
    // document.body.style.top = '';
    // window.scrollTo(0, parseInt(top || '0') * -1);
  } else {
    // Open Toggle
    toggle.classList.add('is-active');
    document.body.classList.add("menu-open");
    this.setAttribute('aria-expanded', 'true');
    //
    // document.body.style.position = 'fixed';
    // document.body.style.top = `-${scrollPosition}px`;
  }
});



var elements = document.getElementsByClassName('js-animate');
for (var i = 0; i < elements.length; i++) {
    var element = elements[i];
    element.className += ' js-animate--pending';
};

var continuousElements = document.getElementsByClassName('js-animate');
for (var i = 0; i < continuousElements.length; i++) {
    new Waypoint({
        element: continuousElements[i],
        handler: function() {
          this.element.classList.add("js-animate--start")
      },
        offset: '100%'
    });
};

var lazyLoadInstance = new LazyLoad({
    elements_selector: ".js-lazy"
});
